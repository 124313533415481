export const InsurancePost = [
    {
        title: "BASIC PACKAGE 1",
        subtitle: "Up to PHP 2M insurance coverage",
        price: "Starting at <b>PHP 423.75</b>",
        description:
        `
         <span>This package contains COVID-19 coverage and all the essentials so you can travel without worry.</span>
         <br><br>
         <span><b>This plan includes:</b></span>
         <br>
         <ul>
            <li>Accidental death and disablement</li>
            <li>Medical expenses including COVID-19</li>
            <li>Personal baggage and delay</li>
            <li>Personal Money and Document loss</li>
            <li>Travel delay, reroute and cancellation</li>
         </ul>
        `,
        footer: "<i>Valid worldwide excluding Schengen states.</i>"
    },
    {
        title: "PREMIUM PACKAGE 1",
        subtitle: "Up to PHP 2M insurance coverage",
        price: "Starting at <b>PHP 423.75</b>",
        description:
        `
         <span>This package contains COVID-19 coverage and all the essentials so you can travel without worry.</span>
         <br><br>
         <span><b>This plan includes:</b></span>
         <br>
         <ul>
            <li>Accidental death and disablement</li>
            <li>Medical expenses including COVID-19</li>
            <li>Personal baggage and delay</li>
            <li>Personal Money and Document loss</li>
            <li>Travel delay, reroute and cancellation</li>
         </ul>
        `,
        footer: "<i>Valid worldwide excluding Schengen states.</i>"
    },
    {
        title: "BASIC PACKAGE 2",
        subtitle: "Up to PHP 2M insurance coverage",
        price: "Starting at <b>PHP 423.75</b>",
        description:
        `
         <span>This package contains COVID-19 coverage and all the essentials so you can travel without worry.</span>
         <br><br>
         <span><b>This plan includes:</b></span>
         <br>
         <ul>
            <li>Accidental death and disablement</li>
            <li>Medical expenses including COVID-19</li>
            <li>Personal baggage and delay</li>
            <li>Personal Money and Document loss</li>
            <li>Travel delay, reroute and cancellation</li>
         </ul>
        `,
        footer: "<i>Valid worldwide excluding Schengen states.</i>"

    },
    {
        title: "PREMIUM PACKAGE 2",
        subtitle: "Up to PHP 2M insurance coverage",
        price: "Starting at <b>PHP 423.75</b>",
        description:
        `
         <span>This package contains COVID-19 coverage and all the essentials so you can travel without worry.</span>
         <br><br>
         <span><b>This plan includes:</b></span>
         <br>
         <ul>
            <li>Accidental death and disablement</li>
            <li>Medical expenses including COVID-19</li>
            <li>Personal baggage and delay</li>
            <li>Personal Money and Document loss</li>
            <li>Travel delay, reroute and cancellation</li>
         </ul>
        `,
        footer: "<i>Valid worldwide excluding Schengen states.</i>"
    },
    {
        title: "BASIC PACKAGE 3",
        subtitle: "Up to PHP 2M insurance coverage",
        price: "Starting at <b>PHP 423.75</b>",
        description:
        `
         <span>This package contains COVID-19 coverage and all the essentials so you can travel without worry.</span>
         <br><br>
         <span><b>This plan includes:</b></span>
         <br>
         <ul>
            <li>Accidental death and disablement</li>
            <li>Medical expenses including COVID-19</li>
            <li>Personal baggage and delay</li>
            <li>Personal Money and Document loss</li>
            <li>Travel delay, reroute and cancellation</li>
         </ul>
        `,
        footer: "<i>Valid worldwide excluding Schengen states.</i>"
    },
    {
        title: "PREMIUM PACKAGE 3",
        subtitle: "Up to PHP 2M insurance coverage",
        price: "Starting at <b>PHP 423.75</b>",
        description:
        `
         <span>This package contains COVID-19 coverage and all the essentials so you can travel without worry.</span>
         <br><br>
         <span><b>This plan includes:</b></span>
         <br>
         <ul>
            <li>Accidental death and disablement</li>
            <li>Medical expenses including COVID-19</li>
            <li>Personal baggage and delay</li>
            <li>Personal Money and Document loss</li>
            <li>Travel delay, reroute and cancellation</li>
         </ul>
        `,
        footer: "<i>Valid worldwide excluding Schengen states.</i>"
    },
    {
        title: "BASIC PACKAGE 4",
        subtitle: "Up to PHP 2M insurance coverage",
        price: "Starting at <b>PHP 423.75</b>",
        description:
        `
         <span>This package contains COVID-19 coverage and all the essentials so you can travel without worry.</span>
         <br><br>
         <span><b>This plan includes:</b></span>
         <br>
         <ul>
            <li>Accidental death and disablement</li>
            <li>Medical expenses including COVID-19</li>
            <li>Personal baggage and delay</li>
            <li>Personal Money and Document loss</li>
            <li>Travel delay, reroute and cancellation</li>
         </ul>
        `,
        footer: "<i>Valid worldwide excluding Schengen states.</i>"

    },
    {
        title: "PREMIUM PACKAGE 4",
        subtitle: "Up to PHP 2M insurance coverage",
        price: "Starting at <b>PHP 423.75</b>",
        description:
        `
         <span>This package contains COVID-19 coverage and all the essentials so you can travel without worry.</span>
         <br><br>
         <span><b>This plan includes:</b></span>
         <br>
         <ul>
            <li>Accidental death and disablement</li>
            <li>Medical expenses including COVID-19</li>
            <li>Personal baggage and delay</li>
            <li>Personal Money and Document loss</li>
            <li>Travel delay, reroute and cancellation</li>
         </ul>
        `,
        footer: "<i>Valid worldwide excluding Schengen states.</i>"
    },
];