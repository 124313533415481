import React, {useState} from "react";
import axios from "axios";
import { Oval } from 'react-loader-spinner';
import { AiOutlineCheck } from 'react-icons/ai';

import { useForm } from 'react-hook-form';

export default function SubscriptionForm2({modal, showNotif}) {
  // const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [subscribe, setSubscribe] = useState(false);

  const  { register, handleSubmit, formState: { errors }, reset} = useForm();

  // axios.defaults.baseURL = `${process.env.REACT_APP_API_ENDPOINT}`;

  const onSubmit = (data) => {
    data.type = 'Subscription2';
    console.log(data, modal);

    // axios.get(`${process.env.REACT_APP_API_ENDPOINT}/sanctum/csrf-cookie`)
    // axios.get(`http://api.tagline360.test:8000/sanctum/csrf-cookie`)
    axios.get(`https://api.tagline360.com/sanctum/csrf-cookie`)
    .then(response => {
      setLoading(true);
      // axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/subscribe`, data)
      // axios.post(`http://api.tagline360.test:8000/api/subscribe`, data)
      data.type = "Subscription2";
      axios.post(`https://api.tagline360.com/api/subscribe`, data)
        .then(response => {
          setLoading(false);
          setSubscribe(true)
          reset();

          if (modal) {
            modal();   
          } 
          showNotif();
          localStorage.setItem('title', 'Our CX Experts Await You!');
          localStorage.setItem('message', 'Thank you for signing up! Please expect an email from us soon.');

          setTimeout(() => { 
            setSubscribe(false)
          }, 1500);
        });
    })
  }
  return (
    <>
      <form onSubmit={ handleSubmit(onSubmit) }>
        <div className="mb-4">
          <input type="text" 
            {...register('rname', { 
              required: true, 
              pattern: /^[A-Za-z ]+$/i 
            }
          )}
          className="text-md py-2 px-3 w-full border" placeholder="NAME *" />
          <div className="text-primary text-sm">
            {errors.rname?.type === 'required' && (<div className="text-primary text-sm">This field is required</div>) }
            {errors.rname?.type === 'pattern' && (<div className="text-primary text-sm">Please enter valid name</div>) }
          </div>
        </div>
        <div className="mb-4">
          <input type="text" 
          {...register('rcontactNo', { 
            required: 'Contact Number is required',
            maxLength: {
              value: 13,
              message: "Must be max 13 chars",
            },
            validate: (value) => {
              return (
                [/^[0-9]+$/].every((pattern) =>
                  pattern.test(value)
                ) || "Please enter numbers only"
              );
            },
          })} 
          className="text-md py-2 px-3 w-full border" placeholder="CONTACT NO. *" />
          <div className="text-primary text-sm">
            {errors.rcontactNo?.message}
          </div>
        </div>
        <div className="mb-4">
          <input type="text" 
            {...register('rbusinessEmail', { 
              required: 'Email address is required',
              maxLength: {
                value: 255,
                message: "Must be max 255 chars",
              },
              validate: (value) => {
                return (
                  [/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/].every((pattern) =>
                    pattern.test(value)
                  ) || "Please enter a valid email"
                );
              },
            })} 
          className="text-md py-2 px-3 w-full border" placeholder="BUSINESS EMAIL *" />
          <div className="text-primary text-sm">
            {errors.rbusinessEmail?.message}
          </div>
        </div>
        <div className="mb-4">
          <button type="submit" className={`${loading ? 'grayscale bg-lightgray cursor-not-allowed':''} bg-[#C2082A] rounded-full py-1 px-4 block ml-auto flex justify-center items-center gap-2`} disabled={loading ? true:false}>
            { loading && (
              <>
                <Oval color="#ffffff" height={20} width={20}  /> 
                <span className="text-white">PROCESSING...</span>
              </>
            )}
            { subscribe && <AiOutlineCheck color="lime" /> }     
            {
              !loading && (
                <span className="text-white">SIGN ME UP FOR MY FREE SESSION</span>
              )
            }       
          </button>
        </div>
      </form>
    </>
  );
}