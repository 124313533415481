// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._header_menu__ycA0J {\n  gap: 2rem; }\n\n._header_menuItem__yq2te {\n  transition: all 0.25s; }\n  ._header_menuItem__yq2te:hover, ._header_menuItem__yq2te:focus {\n    transform: scale(1.05) translateY(-2px); }\n  ._header_menuItem__yq2te a {\n    font-family: \"Sarabun\", sans-serif;\n    color: #EF5399;\n    padding: .25rem;\n    font-weight: 600;\n    margin-right: 30px;\n    display: inline-block; }\n\n._header_getInTouchBtn__7ejaN {\n  font-family: \"Sarabun\", sans-serif;\n  color: #EF5399;\n  padding: .25rem;\n  font-weight: 600;\n  margin-right: 30px; }\n  ._header_getInTouchBtn__7ejaN:hover, ._header_getInTouchBtn__7ejaN:focus {\n    transform: scale(1.05) translateY(-2px); }\n", "",{"version":3,"sources":["webpack://./src/css/_header.module.scss"],"names":[],"mappings":"AAUA;EACI,SAAS,EAAA;;AAEb;EACI,qBAAqB,EAAA;EADzB;IAGQ,uCAAuC,EAAA;EAH/C;IAVI,kCAAkC;IAClC,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAad,qBAAqB,EAAA;;AAK7B;EAtBI,kCAAkC;EAClC,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,kBAAkB,EAAA;EAkBtB;IAGQ,uCAAuC,EAAA","sourcesContent":["@import './variables';\n\n@mixin li {\n    font-family: \"Sarabun\", sans-serif;\n    color: #EF5399;\n    padding: .25rem;\n    font-weight: 600;\n    margin-right: 30px;\n}\n\n.menu {\n    gap: 2rem;\n}\n.menuItem {\n    transition: all 0.25s;\n    &:hover, &:focus {\n        transform: scale(1.05) translateY(-2px);\n    }\n    a {\n        @include li;\n        display: inline-block;\n    }\n    \n}\n\n.getInTouchBtn {\n    @include li;\n    &:hover, &:focus {\n        transform: scale(1.05) translateY(-2px);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": "_header_menu__ycA0J",
	"menuItem": "_header_menuItem__yq2te",
	"getInTouchBtn": "_header_getInTouchBtn__7ejaN"
};
export default ___CSS_LOADER_EXPORT___;
